import * as React from 'react';

import i18next, { type InitOptions } from 'i18next';
import {
  I18nextProvider,
  I18nextProviderProps,
  initReactI18next,
} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HTTPBackend from 'i18next-http-backend';
import { getInitialNamespaces } from 'remix-i18next';
import { i18nConfig } from './config';

export async function initClientI18n() {
  let ns: InitOptions['ns'];
  try {
    // We might or might not in Remix context (window.__remixRouteModules) hence try/catch
    // This function detects the namespaces your routes rendered while SSR use
    ns = getInitialNamespaces();
  } catch (err) {}

  await i18next
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(LanguageDetector) // Setup a client-side language detector
    .use(HTTPBackend) // Setup your backend
    .init({
      ...i18nConfig,
      ...(ns && { ns }),
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ['htmlTag'],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: [],
      },
    });

  function I18nProvider({ children }: { children: React.ReactNode }) {
    return React.createElement<I18nextProviderProps>(
      I18nextProvider,
      { i18n: i18next, defaultNS: i18nConfig.defaultNS },
      children
    );
  }

  return { I18nProvider };
}
